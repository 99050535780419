import Service from "../Service";
import Vue from "vue";
const resource = "maintenancegeneral/"

export default {
    save(obj, requestID) {
      return Service.post(resource + "save", obj, {
        params: { requestID: requestID },
      });   
    },

    pagination(parameters, requestID) {
      return Service.post(resource + "pagination", parameters, {
        params: { requestID: requestID },
      });
    },
};